import { SET_CURRENT_TIME } from '../constants'

export default (state = { currentTime: null }, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload }
    default:
      return state
  }
}
