import t from 'format-message'
import humps from 'humps'
import queryString from 'query-string'
import throttle from 'lodash/throttle'

import { refreshToken as refreshTokenAction } from '../actions/apiActions'
import { needsRefresh } from '../util/tokenChecks'
import { TOKEN_SYNC_THROTTLE_WINDOW_MS } from '../constants'
export default class LtiApiService {
  constructor (options = { concurrency: true }) {
    /* eslint-disable immutable/no-mutation */
    this.concurrency = options.concurrency || 1
    this.requests = []
    /* eslint-enable immutable/no-mutation */
  }

  GET ({ path, resolveAction, onError, query }, store) {
    return this.request(
      { method: 'GET', path, store, onError, resolveAction, query }
    )
  }

  PATCH ({ body, path, resolveAction, onError, query }, store) {
    return this.request(
      { method: 'PATCH', body, path, resolveAction, store, onError, query }
    )
  }

  POST ({ body, path, resolveAction, onError, query }, store) {
    return this.request(
      { method: 'POST', body, path, resolveAction, store, onError, query }
    )
  }

  PUT ({ body, path, resolveAction, onError, query }, store) {
    return this.request(
      { method: 'PUT', body, path, resolveAction, store, onError, query }
    )
  }

  async request ({ method, path, body, store, onError, resolveAction, query }) {
    const token = store.getState().oauthToken
    const refreshCheck = needsRefresh({method, path, token})

    // preliminary check, may or may not be throttled
    if (refreshCheck.expired || refreshCheck.expiring) {
      // subsequent requests within 25 secs will be throttled
      await this.requestRefreshToken(store, method, path)
    }

    // Get the token from the store anew as it may have been refreshed
    const request = new Request(
      this.path(path, query), this.buildRequestParams({ method, token: store.getState().oauthToken, body })
    )
    return this.enqueue(request, this.resolveActionHandler(resolveAction, store), onError)
  }

  // Helper functions
  async enqueue (request, handler, onError) {
    if (this.concurrency !== true && this.requests.length >= this.concurrency) {
      const complete = await Promise.race(this.requests)
      this.requests.splice(this.requests.indexOf(complete), 1)
    }
    return this.send(request, handler, onError)
  }

  requestRefreshToken = throttle(async (store, method, path) => {
    // the following 2 lines are not just redundance of the 2 lines in request method
    // the 2nd response of the throttle method may avoid sending refreshToken
    // requests based on the check inside of the method
    const token = store.getState().oauthToken
    const refreshCheck = needsRefresh({method, path, token})

    if (refreshCheck.expired) { // If we need a refresh, trigger it now
      await this.refreshToken(token, store)
    } else if (refreshCheck.expiring) {
      store.dispatch(refreshTokenAction(token))
    }
  }, TOKEN_SYNC_THROTTLE_WINDOW_MS)

  refreshToken (token, store) {
    const serviceAction = refreshTokenAction(token)
    const { body, path, resolveAction } = serviceAction.payload.args[0]
    const onError = serviceAction.payload.onError
    const method = serviceAction.payload.method
    const request = new Request(
      this.path(path), this.buildRequestParams({ method, token, body })
    )

    return this.send(request, this.resolveActionHandler(resolveAction, store), onError)
  }

  send (request, handler, onError) {
    const promise = fetch(request)
      .then(this.checkResponse)
      .then(this.parseJSON)
      .then(handler)
      // TODO: Tie this into alerting error handling
      .catch(onError ? onError : console.error) // eslint-disable-line no-console

    if (this.concurrency !== true) {
      this.requests.push(promise)
    }

    return promise
  }

  buildRequestParams ({body, method, token}) {
    if (!token) {
      throw new Error(t('Unsuccessful LTI API request: you forgot a token'))
    }
    if (!token.accessToken) {
      throw new Error(t('Invalid token: { token }', { token: JSON.stringify(token) }))
    }

    const params = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token.accessToken}`,
        'Content-Type': 'application/json'
      },
      method
    }

    if (body) {
      // eslint-disable-next-line immutable/no-mutation
      params.body = JSON.stringify(body)
    }

    return params
  }

  resolveActionHandler (resolveAction, store) {
    return ({ json, response }) => {
      if (resolveAction) {
        store.dispatch(resolveAction(json, response))
      }
      return { json, response }
    }
  }

  path (path, query) {
    if (!path) {
      throw new Error(t('Unsuccessful LTI API request: missing path'))
    }
    const basePath = '/api'
    const endPath = path.startsWith('/') ? path : `/${path}`
    const queryPath = query ? this.queryString(query) : ''

    return `${basePath}${endPath}${queryPath}`
  }

  queryString (query) {
    return '?' + queryString.stringify(humps.decamelizeKeys(query), { arrayFormat: 'bracket' })
  }

  checkResponse (response) {
    if (response.ok) {
      return response
    }

    const error = new Error(t('Unsuccessful LTI API request: status { status }', { status: response.statusText }))

    error.response = response // eslint-disable-line immutable/no-mutation
    throw error
  }

  parseJSON (response) {
    return new Promise((resolve, reject) => {
      if (response.status === 204) {
        resolve({ json: {}, response })
      }

      response.json()
        .then((json) => resolve({ json, response }))
        .catch(reject)
    })
  }
}
