import { CREATE_OR_UPDATE_ASSIGNMENT_SESSION } from '../constants'
import multipleAttempts from '../common/lib/multipleAttempts'

export const assignmentSession = (apiResponse) => {
  return {
    anonymousGrading: apiResponse.anonymous_grading,
    contextUuid: apiResponse.context_uuid,
    externalAccountUuid: apiResponse.external_account_uuid,
    host: apiResponse.host,
    multipleAttempts: multipleAttempts(apiResponse.multiple_attempts),
    outcomesHost: apiResponse.outcomes_host,
    outcomesToken: apiResponse.outcomes_token,
    quizId: String(apiResponse.quiz_api_quiz_id),
    resultViewSettings: apiResponse.result_view_settings,
    status: apiResponse.status,
    token: apiResponse.token
  }
}

export const createAssignmentSessionHandler = (scope, outcomesScope) => (apiResponse) => {
  return {
    type: CREATE_OR_UPDATE_ASSIGNMENT_SESSION,
    scope,
    outcomesScope,
    assignmentSession: assignmentSession(apiResponse)
  }
}

export const handleAssignmentSessionUpdate = createAssignmentSessionHandler
