import {
  PARTICIPANT_UPDATED,
  MODERATE_TRAY_CLOSED,
  OPEN_MODERATE_TRAY,
  UPDATE_EXTRA_TIME_PER_QUIZ
} from '../constants'
import slice from '../util/slice'

const initialState = {
  disableTimerQuiz: false,
  extraTimeEnabledQuiz: false,
  extraTimeInSecondsQuiz: 0,
  participantId: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PARTICIPANT_UPDATED: return (() => {
      const { participantId } = state
      if (action.participantId !== participantId) {
        return state
      }

      return extraTimeSettings({
        ...state,
        ...action.participant,
        participantId: action.participantId
      })
    })()

    case MODERATE_TRAY_CLOSED:
      return initialState

    case OPEN_MODERATE_TRAY:
      return extraTimeSettings({
        ...state,
        ...action.participant,
        participantId: action.participant.id
      })

    case UPDATE_EXTRA_TIME_PER_QUIZ:
      return {
        ...state,
        ...action.settings,
      }

    default:
      return state
  }
}

function extraTimeSettings (participant) {
  const settings = slice(participant, Object.keys(initialState))
  return {
    ...settings,
    ...giveExtraTimeSettings(settings)
  }
}

function giveExtraTimeSettings (settings) {
  const disableTimerQuiz = Boolean(settings.disableTimerQuiz && true)
  const extraTimeEnabledQuiz = Boolean(settings.extraTimeEnabledQuiz && true)
  const extraTimeInSecondsQuiz = Number(settings.extraTimeInSecondsQuiz || 0)
  return { disableTimerQuiz, extraTimeEnabledQuiz, extraTimeInSecondsQuiz }
}
