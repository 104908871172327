import { STORE_VERSION } from '../constants'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case STORE_VERSION:
      // Merge
      return Object.assign(
        {},
        state,
        Object.assign({}, state.versions, action.payload)
      )
    default:
      return state
  }
}
