import { STORE_USERS } from '../constants'

const userData = (apiResponse) => {
  return {
    avatarImageUrl: apiResponse.avatar_image_url,
    fullName: apiResponse.full_name,
    htmlUrl: apiResponse.html_url
  }
}

export default (state = {}, action = {}) => {
  switch (action.type) {
    case STORE_USERS:
      // Merge
      return Object.assign(
        {},
        state,
        ...action.users.map((user) => ({ [user.id]: userData(user) }))
      )
    default:
      return state
  }
}
