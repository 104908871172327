import { CREATE_PARTICIPANT_SESSION } from '../constants'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case CREATE_PARTICIPANT_SESSION:
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          ...action.participantSessions
        }
      }
    default:
      return {
        ...state
      }
  }
}
