import { navigateTo } from './path'

export const navigateToGrading = (activeAssignmentId, renderBackLink=false) => (apiResponse) => {
  const participantSessionId = apiResponse.participant_session.id
  return navigateTo(`/moderation/${activeAssignmentId}/grading/${participantSessionId}?renderBackLink=${renderBackLink}`)
}

export const navigateToParticipantSession = (apiResponse) => {
  const participantSessionId = apiResponse.id
  return navigateTo(`/taking/${participantSessionId}`)
}

export const navigateToResults = (apiResponse) => {
  const participantSessionId = apiResponse.participant_session.id
  return navigateTo(`/taking/${participantSessionId}/results`)
}
