import {
  GRANT_TYPE_REFRESH_TOKEN,
  STORE_OAUTH_TOKEN
} from '../constants'

const updateTokenInfo = (data) => {
  return {
    createdAt: data.created_at,
    expiresIn: data.expires_in,
    refreshToken: data.refresh_token,
    scope: data.scope || data.scopes.join(','),
    tokenType: GRANT_TYPE_REFRESH_TOKEN,
    // Only update the access token if it exists
    ...( data.access_token && {accessToken:  data.access_token})
  }
}

export function storeOauthToken (accessToken) {
  return {
    tokenInfo: { accessToken },
    type: STORE_OAUTH_TOKEN
  }
}

export function updateOauthToken (data) {
  return {
    tokenInfo: updateTokenInfo(data),
    type: STORE_OAUTH_TOKEN
  }
}
