import {NAVIGATE_TO} from '../constants'

const navigationMiddleware = (router) => (store) => (next) => (action) => {
  if (action.type === NAVIGATE_TO) {
    router.navigate(action.path)
  }

  return next(action)
}

export default navigationMiddleware
