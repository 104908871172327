import debounce from 'debounce'

import { UPDATE_EXTRA_TIME_PER_QUIZ } from '../constants'
import { updateParticipant } from '../actions/apiActions'

const saveExtraTimePerQuiz = debounce((store) => {
  const state = store.getState()
  const { giveExtraTimeSettings } = state
  if (!extraTimePerQuizValid(giveExtraTimeSettings)) {
    return
  }

  const { participantId } = giveExtraTimeSettings
  const participant = formatParticipant(giveExtraTimeSettings)
  store.dispatch(updateParticipant({ participantId, participant }))
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default (store) => (next) => (action) => {
  if (action.type === UPDATE_EXTRA_TIME_PER_QUIZ) {
    saveExtraTimePerQuiz(store)
  }
  return next(action)
}

function extraTimePerQuizValid (settings) {
  if ( ! settings ) { return false }
  const valid_enable = !(settings.disableTimerQuiz && settings.extraTimeEnabledQuiz)
  const valid_time = !settings.extraTimeEnabledQuiz || isValidValue(settings.extraTimeInSecondsQuiz)
  return valid_enable && valid_time
}

function isValidValue (number) {
  const value = Number(number)
  return Number.isInteger(value) && value >= 0
}

function formatParticipant (settings) {
  /* eslint-disable immutable/no-mutation */
  const mapping = {
    disableTimerQuiz: 'disable_timer',
    extraTimeEnabledQuiz: 'extra_time_enabled',
    extraTimeInSecondsQuiz: 'extra_time_in_seconds'
  }

  const formatted = {}
  Object.keys(mapping)
    .filter((key) => Object.prototype.hasOwnProperty.call(settings, key))
    .forEach((key) => { formatted[mapping[key]] = settings[key] })

  if ( extraTimePerQuizValid(settings) ) {
    formatted.disable_timer = settings.disableTimerQuiz
    formatted.extra_time_enabled = settings.extraTimeEnabledQuiz
    formatted.extra_time_in_seconds = settings.extraTimeInSecondsQuiz
  }

  return formatted
  /* eslint-enable immutable/no-mutation */
}
