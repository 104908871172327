import debounce from 'debounce'

import { UPDATE_TIMER_SETTINGS } from '../constants'
import { updateEnrollment } from '../actions/apiActions'

const saveTimerSettings = debounce((store) => {
  const state = store.getState()
  const { timerSettings } = state
  if (!timerSettingsValid(timerSettings)) {
    return
  }

  const { contextId, participantId, userId } = timerSettings
  const enrollment = formatTimerSettings(timerSettings)
  store.dispatch(updateEnrollment({ contextId, participantId, userId, enrollment }))
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default (store) => (next) => (action) => {
  if (action.type === UPDATE_TIMER_SETTINGS) {
    saveTimerSettings(store)
  }
  return next(action)
}

function timerSettingsValid (settings) {
  const { extraTimeEnabled, extraTimeValue, timerMultiplierEnabled, timerMultiplierValue } = settings
  if (extraTimeEnabled && !isNumeric(extraTimeValue)) {
    return false
  }

  if (timerMultiplierEnabled && !isNumeric(timerMultiplierValue)) {
    return false
  }

  return true
}

function isNumeric (number) {
  // Number accepts empty strings and strings with trailing dot
  return !Number.isNaN(Number(number)) && /[^.]$/.test(number)
}

function formatTimerSettings (settings) {
  /* eslint-disable immutable/no-mutation */
  const mapping = {
    disableTimer: 'disable_timer',
    extraTimeEnabled: 'extra_time_enabled',
    increaseTimeAllowance: 'increase_time_allowance',
    timerMultiplierEnabled: 'timer_multiplier_enabled'
  }

  const formatted = {}
  Object.keys(mapping)
    .filter((key) => Object.prototype.hasOwnProperty.call(settings, key))
    .forEach((key) => { formatted[mapping[key]] = settings[key] })

  const extraTimeValue = Number(settings.extraTimeValue)
  if (!Number.isNaN(extraTimeValue)) {
    formatted.extra_time_in_seconds = extraTimeInSeconds(extraTimeValue, settings.extraTimeUnit)
  }

  const timerMultiplierValue = Number(settings.timerMultiplierValue)
  if (!Number.isNaN(timerMultiplierValue)) {
    formatted.timer_multiplier_value = timerMultiplierValue
  }

  return formatted
  /* eslint-enable immutable/no-mutation */
}

function extraTimeInSeconds (value, unit) {
  switch (unit) {
    case 'minutes':
      return value * 60
    case 'hours':
      return value * 3600
    default:
      throw new Error(`Invalid unit: ${unit}`)
  }
}
