import {
  CREATE_MODERATION_PARTICIPANTS,
  ADD_PARTICIPANTS_IN_PROGRESS,
  ENROLLMENT_UPDATED,
  UPDATE_MODERATION_PAGE,
  UPDATE_MODERATION_PROGRESS_FILTER,
  UPDATE_MODERATION_SEARCH,
  PARTICIPANT_UPDATED,
  SET_ASSIGNMENT_TIMELIMIT,
  SET_NUM_AUTOGRADING_FAILED
} from '../constants'

const initialState = {
  currentPage: 1,
  progressFilter: '',
  search: '',
  totalPages: 0
}

function createModerationParticipants (state, action) {
  const participants = {}
  action.participants.forEach((p) => { participants[p.id] = p }) // eslint-disable-line immutable/no-mutation

  return {
    ...state,
    participants,
    totalPages: action.totalPages
  }
}

function addInProgressParticipants (state, action) {
  const inProgressParticipants = {}
  action.inProgressParticipants.forEach((p) => { inProgressParticipants[p.id] = p }) // eslint-disable-line immutable/no-mutation

  return {
    ...state,
    inProgressParticipants
  }
}

function enrollmentUpdated (state, action) {
  const { participantId, enrollment } = action
  const participant = state.participants[participantId]

  if (!participant) {
    return state
  }

  return {
    ...state,
    participants: {
      ...state.participants,
      [participantId]: { ...participant, ...enrollment }
    }
  }
}

function participantUpdated (state, action) {
  const { participantId, participant } = action
  const prevPaticipant = state.participants[participantId]

  if (!prevPaticipant) {
    return state
  }

  return {
    ...state,
    participants: {
      ...state.participants,
      [participantId]: { ...prevPaticipant, ...participant }
    }
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case CREATE_MODERATION_PARTICIPANTS: return createModerationParticipants(state, action)

    case ADD_PARTICIPANTS_IN_PROGRESS: return addInProgressParticipants(state, action)

    case ENROLLMENT_UPDATED: return enrollmentUpdated(state, action)

    case PARTICIPANT_UPDATED: return participantUpdated(state, action)

    case UPDATE_MODERATION_PAGE:
      return { ...state, currentPage: action.page }

    case SET_ASSIGNMENT_TIMELIMIT:
      return {
        ...state,
        sessionTimeLimitInSeconds: action.sessionTimeLimitInSeconds,
        hasSessionTimeLimit: action.hasSessionTimeLimit
      }

    case UPDATE_MODERATION_PROGRESS_FILTER:
      return {
        ...state,
        progressFilter: action.progressFilter || ''
      }

    case UPDATE_MODERATION_SEARCH:
      return {
        ...state,
        search: action.search || ''
      }

    case SET_NUM_AUTOGRADING_FAILED:
      return {
        ...state,
        numAutogradingFailed: action.numAutogradingFailed
      }

    default:
      return state
  }
}
