/* eslint immutable/no-mutation:0, max-len:0, no-console:0 */
/* istanbul ignore file */
import config from '../../config'

import { ACCESS_TOKEN_STORAGE_KEY } from '../../constants'

// ==============================================================
// ==============================================================

// this is loaded in development for the purposes of easily
// becoming different users

// tokens should match those generated by easy_auth rake task

// ==============================================================
// ==============================================================

const STORAGE_KEY = 'inst-quizzes-lti-role-to-spoof'

const getToken = (user) => {
  return {
    adminA: config.adminAToken,
    adminB: config.adminBToken,
    adminC: config.adminCToken,
    adminD: config.adminDToken,
    adminE: config.adminEToken,
    teacherA: config.teacherAToken,
    teacherB: config.teacherBToken,
    teacherC: config.teacherCToken,
    teacherD: config.teacherDToken,
    teacherE: config.teacherEToken,
    studentA: config.studentAToken,
    studentB: config.studentBToken,
    studentC: config.studentCToken,
    studentD: config.studentDToken,
    studentE: config.studentEToken
  }[user]
}

const becomeUserOfType = (userType) => {
  console.log(
    (`Using site as: ${userType}`),
    '\nif you do not want to be this user type run "window.forgetMeNow()"'
  )
  return getToken(userType)
}

const spoofRole = () => {
  let userType = null
  try {
    userType = window.localStorage.getItem(STORAGE_KEY)
  } catch (e) {
    console.error('Cannot spoof role, localStorage not available')
    return
  }
  if (userType) {
    return becomeUserOfType(userType)
  } else {
    const msg = `using app like normal: run become[Admin|Teacher|Student]
                  to become the user type of your choice.`
    console.log(msg)
  }
}

const unsetRole = () => {
  window.localStorage.removeItem(STORAGE_KEY)
  window.sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
}

const setRole = (userType) => {
  unsetRole()
  window.localStorage.setItem(STORAGE_KEY, userType)
  window.location.reload()
}

// create globally accessible convenience methods
window.becomeAdmin = () => setRole('adminA')
window.becomeAdminA = () => setRole('adminA')
window.becomeAdminB = () => setRole('adminB')
window.becomeAdminC = () => setRole('adminC')
window.becomeAdminD = () => setRole('adminD')
window.becomeAdminE = () => setRole('adminE')
window.becomeTeacher = () => setRole('teacherA')
window.becomeTeacherA = () => setRole('teacherA')
window.becomeTeacherB = () => setRole('teacherB')
window.becomeTeacherC = () => setRole('teacherC')
window.becomeTeacherD = () => setRole('teacherD')
window.becomeTeacherE = () => setRole('teacherE')
window.becomeStudent = () => setRole('studentA')
window.becomeStudentA = () => setRole('studentA')
window.becomeStudentB = () => setRole('studentB')
window.becomeStudentC = () => setRole('studentC')
window.becomeStudentD = () => setRole('studentD')
window.becomeStudentE = () => setRole('studentE')

window.forgetMeNow = () => {
  unsetRole()
  window.location.reload()
}

export default spoofRole
