import { ADD_ALERT, REMOVE_ALERT } from '../constants'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      }
    case REMOVE_ALERT: {
      const { [action.payload.uuid]: _, ...rest } = state
      return rest
    }
    default:
      return state
  }
}
