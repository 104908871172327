import React from 'react'
import Router from 'middle-router'
import t from 'format-message'

import { NotFound } from 'quiz-react-sdk/es/common/components/pages/NotFound'

export default function createRouter (env = process.env) {
  return Router()
    .lazy('/banks', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./banks/router').default), 'banks'
        )
      })
    })
    .lazy('/build/:activeAssignmentId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./building/router').default), 'building'
        )
      })
    })
    .lazy('/reporting/:activeAssignmentId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./reporting/router').default), 'reporting'
        )
      })
    })
    .lazy('/moderation/:activeAssignmentId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./moderation/router').default), 'moderation'
        )
      })
    })
    .lazy('/taking/:activeParticipantSessionId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./taking/router').default), 'taking'
        )
      })
    })
    .lazy('/observing/:activeAssignmentId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./observing/router').default), 'observing'
        )
      })
    })
    .lazy('/moderation/:activeAssignmentId/grading/:activeParticipantSessionId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./grading/router').default), 'grading'
        )
      })
    })
    .lazy('/exports/:activeAssignmentId', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./contentExports/router').default), 'contentExports'
        )
      })
    })
    .lazy('/errors/:status', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./errors/router').default), 'errors'
        )
      })
    })
    .lazy('/version', ({params}) => {
      return new Promise((resolve) => {
        require.ensure(
          [], (require) => resolve(require('./version/router').default), 'version'
        )
      })
    })
    // FIXME: redirect to / in production
    .use('/*', ({ path, resolve }) => {
      resolve({
        title: t('Not Found'),
        view: <NotFound />
      })
    })
}
