import Raven from 'raven-js'

export default function createRaven (env = process.env) {
  const { NODE_ENV, PUBLIC_SENTRY_DSN, GIT_SHA, REPORT_TO_SENTRY } = env

  // NOTE: we return the Raven singleton here for testing but keep in mind
  // that the createRaven function is called for its side effect of
  // attaching a global error event handler
  return Raven.isSetup() ? Raven : Raven
    .config(PUBLIC_SENTRY_DSN, {
      environment: NODE_ENV,
      release: GIT_SHA,
      shouldSendCallback () { return REPORT_TO_SENTRY },
      ignoreUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i
      ]
    })
    .install()
}
