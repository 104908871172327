import { LOAD_PREVIEW_DATA, CLEAR_PREVIEW_DATA } from '../constants'
import { participantSession } from './participantSessions'

export function loadPreviewData (apiResponse) {
  return {
    type: LOAD_PREVIEW_DATA,
    data: {
      session: participantSession(apiResponse),
      quizSessionId: apiResponse.quiz_api_quiz_session_id,
      quizSessionTakeToken: apiResponse.quiz_session_take_token,
      quizSessionResultsToken: apiResponse.quiz_session_results_token
    }
  }
}

export function clearPreviewData () {
  return {
    type: CLEAR_PREVIEW_DATA
  }
}
