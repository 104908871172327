import { CREATE_OR_UPDATE_ASSIGNMENT_SESSION } from '../constants'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case CREATE_OR_UPDATE_ASSIGNMENT_SESSION:
      return {
        ...state,
        [action.scope]: action.assignmentSession
      }
    default:
      return state
  }
}
