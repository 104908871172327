import debounce from 'debounce'

import { UPDATE_EXTRA_ATTEMPTS_PER_QUIZ, PARTICIPANT_UPDATED } from '../constants'
import { updateParticipant, getParticipantsForModeration } from '../actions/apiActions'

const saveExtraAttemptsPerQuiz = debounce((store, action) => {
  const state = store.getState()
  const { giveExtraAttemptsSettings, moderation } = state
  if (!extraAttemptsPerQuizValid(giveExtraAttemptsSettings)) {
    return
  }

  const { participantId } = giveExtraAttemptsSettings
  const participant = formatParticipant(giveExtraAttemptsSettings)
  const postActionHandler = buildPostAction(moderation, action)
  store.dispatch(updateParticipant({
    participantId, participant, postActionHandler
  }))
}, 250)

const postUpdateModeration = debounce((store, action) => {
  const { postActionHandler } = action
  if (postActionHandler) {
    store.dispatch(postActionHandler())
  }
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default (store) => (next) => (action) => {
  if (action.type === UPDATE_EXTRA_ATTEMPTS_PER_QUIZ) {
    saveExtraAttemptsPerQuiz(store, action)
  }
  else if (action.type === PARTICIPANT_UPDATED) {
    postUpdateModeration(store, action)
  }
  return next(action)
}

function extraAttemptsPerQuizValid (settings) {
  return isValidValue(settings.extraAttemptsQuiz)
}

function isValidValue (number) {
  const value = Number(number)
  return Number.isInteger(value) && value >= 0
}

function formatParticipant (settings) {
  /* eslint-disable immutable/no-mutation */
  const mapping = {
    extraAttemptsQuiz: 'extra_attempts'
  }

  const formatted = {}
  Object.keys(mapping)
    .filter((key) => Object.prototype.hasOwnProperty.call(settings, key))
    .forEach((key) => { formatted[mapping[key]] = settings[key] })

  if (extraAttemptsPerQuizValid(settings)) {
    formatted.extra_attempts = settings.extraAttemptsQuiz
  }

  return formatted
  /* eslint-enable immutable/no-mutation */
}

function buildPostAction (moderation, action) {
  const { activeAssignmentId } = action.settings
  const { currentPage, progressFilter, search } = moderation

  if(progressFilter !== 'no_attempts_left') {
    return null
  }

  return updateModeration.bind(
    null,
    activeAssignmentId,
    currentPage,
    progressFilter,
    search
  )
}

function updateModeration (
  activeAssignmentId,
  currentPage,
  progressFilter,
  search
) {
  return getParticipantsForModeration({
    activeAssignmentId,
    page: currentPage,
    progressFilter,
    search
  })
}
